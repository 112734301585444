import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { mdiFacebook, mdiInstagram, mdiTwitter } from "@mdi/js"
import Icon from "@mdi/react"

import logo from "../assets/images/logo-grey.png"

import cx from "classnames"
import styles from "./footer.module.scss"

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.content}>
      <a
        className={styles.logo_link}
        href="http://leavers.nzuniforms.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="NZ Uniforms logo" className={styles.logo} src={logo} />
      </a>
      <div className={styles.links}>
        <AnchorLink
          className={cx(styles.item, styles.highlighted)}
          href="#designer"
          offset={80}
        >
          Design Your Own
        </AnchorLink>
        <AnchorLink
          className={cx(styles.item)}
          href="#image-gallery"
          offset={80}
        >
          Image Gallery
        </AnchorLink>
        <AnchorLink className={cx(styles.item)} href="#contact" offset={80}>
          Contact Us
        </AnchorLink>
        {/*
        <a
          className={cx(styles.item, styles.secondary)}
          href="https://www.nzuniforms.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Order Portal
        </a>*/}
      </div>
    </div>
    <div className={styles.socials}>
      <p>Follow us:</p>
      <a
        href="https://www.facebook.com/NZUniforms"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon className={styles.icon} path={mdiFacebook} />
      </a>
      <a
        href="https://instagram.com/nzuniforms/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon className={styles.icon} path={mdiInstagram} />
      </a>
      <a
        href="https://twitter.com/NzUniforms"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon className={styles.icon} path={mdiTwitter} />
      </a>
    </div>
  </footer>
)

export default Footer
