import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Button from "./button"

import styles from "./ready.module.scss"

const Ready = () => {
  const image = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "ready" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(quality: 100, maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.fluid

  return (
    <BackgroundImage
      backgroundColor="#ffffff"
      className={styles.background}
      fluid={image}
    >
      <div className={styles.overlay}>
        <section className={styles.section}>
          <h2>Ready To Go?</h2>
          <div className={styles.buttons}>
            <Button anchor link="#designer">
              Use our design tool
            </Button>
            <Button anchor link="#contact" tertiary>
              Get in touch with our design team
            </Button>
          </div>
        </section>
      </div>
    </BackgroundImage>
  )
}
export default Ready
