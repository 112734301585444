import React, { useState, useEffect } from "react"

import styles from "./designer.module.scss"

//import "../components/kit-builder.scss"

const Designer = () => {
  useEffect(() => {
    const addScript = (url) => {
      const script = document.createElement("script")
      script.src = url
      script.async = true
      document.body.appendChild(script)
    }

    addScript(
      "https://api.kitbuilder.co.uk/api/bootstrapper?distributorId=85093207&key=b0352743bc1743508456ba4bc66f83c7"
    )

    addScript("https://nzuniforms.com/ViewsCustom/nzu/js/kbcartbridge.js?v=1")

    const addCSS = (url) => {
      const link = document.createElement("link")
      link.type = "text/css"
      link.rel = "stylesheet"
      link.href = url
      document.body.appendChild(link)
    }

    addCSS("https://nzuniforms.com/ViewsCustom/nzu/css/kbcartbridge.css?v=1")

    // If URL !== 'final', redirect to home catalogue
    window.location.href.indexOf("final") === -1 &&
      window.history.pushState(null, null, `/#/catalogue`)

    document.addEventListener("KitBuilderLoaded", function () {
      window.KitBuilder.init("#builder")
    })
  }, [])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    // If URL contains final?token, setCompleted(true)
    window.location.href.indexOf("final?token") > -1 && setCompleted(true)
  })

  return (
    <div className={styles.background}>
      <section className={styles.section} id="designer">
        <div className={styles.intro}>
          <h2>Design Your Own</h2>
          <p>
            Have a design in mind or want to create a mock up with your company
            colours? Explore the possibilities with our interactive design tool.
            Upload your logo or design and select colours, logo placements and
            more.
          </p>
          <p>
            Once you've completed a design, contact us using the form further
            down the page, and we'll work with you to bring it to life!
          </p>
          <div className={styles.tool} id="builder"></div>
          {completed === true && (
            <p className={styles.red}>
              <b>Fill out our contact form below to send us your design!</b>
            </p>
          )}
        </div>
        <div className={styles.tool} id="builder"></div>
      </section>
    </div>
  )
}

export default Designer
