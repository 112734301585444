import React, { useState } from "react"
import Icon, { Stack } from "@mdi/react"
import { mdiMenu, mdiClose } from "@mdi/js"
import AnchorLink from "react-anchor-link-smooth-scroll"

import cx from "classnames"
import styles from "./navigation.module.scss"

const Navigation = () => {
  const [navOpened, setNavOpened] = useState(false)

  return (
    <>
      <button
        className={cx(styles.toggler, navOpened && styles.open)}
        onClick={() => setNavOpened(!navOpened)}
      >
        <Stack className={styles.stack} color="#b20a20">
          <Icon
            className={cx(styles.icon, navOpened && styles.hidden)}
            path={mdiMenu}
          />
          <Icon
            className={cx(styles.icon, !navOpened && styles.hidden)}
            path={mdiClose}
          />
        </Stack>
      </button>
      <nav className={cx(styles.mobile, navOpened && styles.open)}>
        <div className={cx(styles.list)}>
          <AnchorLink
            className={cx(styles.item, styles.highlighted)}
            href="#designer"
            offset={80}
            onClick={() => setNavOpened(false)}
          >
            Design Your Own
          </AnchorLink>
          <AnchorLink
            className={cx(styles.item)}
            href="#how-it-works"
            offset={80}
            onClick={() => setNavOpened(false)}
          >
            Image Gallery
          </AnchorLink>
          <AnchorLink
            className={cx(styles.item)}
            href="#contact"
            offset={80}
            onClick={() => setNavOpened(false)}
          >
            Contact Us
          </AnchorLink>
          {/*
          <a
            className={cx(styles.item, styles.secondary)}
            href="https://www.nzuniforms.com"
            onClick={() => setNavOpened(false)}
            rel="noopener noreferrer"
            target="_blank"
          >
            Order Portal
          </a>*/}
        </div>
      </nav>
      <nav className={cx(styles.desktop)}>
        <AnchorLink
          className={cx(styles.item, styles.highlighted)}
          href="#designer"
          offset={80}
        >
          Design Your Own
        </AnchorLink>
        <AnchorLink
          className={cx(styles.item)}
          href="#image-gallery"
          offset={80}
        >
          Image Gallery
        </AnchorLink>
        <AnchorLink className={cx(styles.item)} href="#contact" offset={80}>
          Contact Us
        </AnchorLink>
        {/*<a
          className={cx(styles.item, styles.secondary)}
          href="https://www.nzuniforms.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Order Portal
        </a>*/}
      </nav>
    </>
  )
}

export default Navigation
