import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import Img from "gatsby-image"

import Button from "./button"

import styles from "./gallery.module.scss"

const Gallery = () => {
  const query = useStaticQuery(graphql`
    query {
      sliderImages: allFile(
        filter: { relativeDirectory: { eq: "images/gallery" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(quality: 100, maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      logoImages: allFile(
        filter: { relativeDirectory: { eq: "images/logos" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const sliderImages = query.sliderImages.edges

  const imagesDisplay = () =>
    sliderImages.map((image, index) => (
      <div className={styles.slide} key={index}>
        <Img
          alt={image.node.name}
          className={styles.image}
          fluid={image.node.childImageSharp.fluid}
          imgStyle={{ objectFit: `contain` }}
        />
      </div>
    ))

  const galleryLength = sliderImages.length

  const galleryScrollSmall = keyframes`
    from { transform: translateX(0); }
    to { transform: translateX(calc(-600px * (${galleryLength} / 2))) }
  `

  const galleryScrollMedium = keyframes`
    from { transform: translateX(0); }
    to { transform: translateX(calc(-900px * (${galleryLength} / 2))) }
  `

  const galleryScrollLarge = keyframes`
    from { transform: translateX(0); }
    to { transform: translateX(calc(-1200px * (${galleryLength} / 2))) }
  `

  const SlideTrack = styled.div`
    align-items: center;
    animation: ${galleryScrollSmall} calc(10s * ${galleryLength}) linear
      infinite;
    display: flex;
    flex-wrap: no-wrap;
    height: 100%;
    width: calc(600px * ${galleryLength});

    @media screen and (min-width: 900px) {
      animation: ${galleryScrollMedium} calc(15s * ${galleryLength}) linear
        infinite;
      width: calc(900px * ${galleryLength});
    }

    @media screen and (min-width: 1800px) {
      animation: ${galleryScrollLarge} calc(15s * ${galleryLength}) linear
        infinite;
      width: calc(1200px * ${galleryLength});
    }
  `

  const logoImages = query.logoImages.edges

  const logoDisplay = () =>
    logoImages.map((logo, index) => (
      <Img
        alt={logo.node.name}
        className={styles.logo}
        fluid={logo.node.childImageSharp.fluid}
        imgStyle={{
          objectFit: `contain`,
        }}
        key={index}
      />
    ))

  return (
    <div className={styles.background}>
      <section className={styles.section} id="image-gallery">
        <div className={styles.intro}>
          <h2>Image Gallery</h2>
          <p>
            Take the hassle out of ordering for large or remote teams with out
            online ordering portal. Choose from a wide range of gear and
            personalisation options, and work with out team to get your branded
            gear produced and delivered in time.
            <br></br>
            <br></br>
            We take pride in every garment we produce, ask us about our money
            back guarantee.
          </p>
        </div>
        <div className={styles.slider}>
          <SlideTrack>
            {imagesDisplay()}
            {imagesDisplay()}
          </SlideTrack>
        </div>
        <Button anchor link="#designer">
          Use our design tool
        </Button>
        <p>No one stocks more brands than us:</p>
        <div className={styles.logos}>{logoDisplay()}</div>
      </section>
    </div>
  )
}

export default Gallery
