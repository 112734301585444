import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

import cx from "classnames"
import styles from "./button.module.scss"

const Button = ({
  anchor,
  children,
  external,
  link,
  primary,
  secondary,
  tertiary,
}) => {
  if (anchor) {
    return (
      <AnchorLink
        className={cx(
          styles.button,
          primary && styles.primary,
          secondary && styles.secondary,
          tertiary && styles.tertiary
        )}
        href={link}
        offset={120}
      >
        {children}
      </AnchorLink>
    )
  } else if (external) {
    return (
      <a
        className={cx(
          styles.button,
          primary && styles.primary,
          secondary && styles.secondary,
          tertiary && styles.tertiary
        )}
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={cx(
          styles.button,
          primary && styles.primary,
          secondary && styles.secondary,
          tertiary && styles.tertiary
        )}
        to={link}
      >
        {children}
      </Link>
    )
  }
}

Button.defaultProps = {
  anchor: false,
  children: "Button Text",
  external: false,
  link: "/",
  primary: true,
  secondary: false,
}

export default Button
