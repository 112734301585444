import React from "react"

import styles from "./terms.module.scss"

const Terms = () => (
  <section className={styles.section} id="terms">
    <h2 className={styles.title}>20% Off Promotion – Terms and Conditions</h2>
    <ul>
      <li>
        20% off applies to wholesale customers who have not ordered from NZ
        Uniforms previously. Orders must be pre-paid in full at the time of
        order placement.
      </li>
      <li>
        20% off cannot be used in conjunction with any other NZ Uniforms
        promotion and cannot be used on retail purchases or on school uniform
        purchases.
      </li>
    </ul>
  </section>
)

export default Terms
