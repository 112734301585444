import React from "react"

import Navigation from "./navigation"

import logo from "../assets/images/logo-grey.png"

import styles from "./header.module.scss"

const Header = () => (
  <header className={styles.header}>
    <div className={styles.content}>
      <a
        className={styles.logo_link}
        href="http://www.nzuniforms.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="NZ Uniforms logo" className={styles.logo} src={logo} />
      </a>
      <Navigation />
    </div>
  </header>
)

export default Header
