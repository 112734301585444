import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Button from "./button"

import styles from "./hero.module.scss"

const Hero = () => {
  const image = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "hero" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(quality: 100, maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.fluid

  return (
    <BackgroundImage
      backgroundColor="#ffffff"
      className={styles.hero}
      fluid={image}
      tag="section"
    >
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h1>Business Gear to Make an Impact</h1>
          <div className={styles.buttons}>
            <Button anchor link="#designer">
              Use our design tool
            </Button>
            <Button anchor link="#contact" tertiary>
              Get in touch with our design team
            </Button>
          </div>
          <p>
            Wear your company pride on your sleeve with branded corporate gear
            for your whole team.
          </p>
          <p>
            As New Zealand's largest supplier of personalised apparel, we are
            experts in helping you design and produce a wide range of branded
            business gear, from work shirts, polos, jackets and merchandise.
            Create custom gear for conferences, company events or for everyday
            work attire.
          </p>
          <p>
            Whether you’re organising gear for a few or a few hundred, we’re
            here to help you bring your brand to life. We’ve made it simple to
            design personalised gear for your business, sports team, club or
            event with our interactive design tool. Browse our range and get
            designing now, or get in touch with our team to chat about your
            branded apparel needs.
          </p>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
