import React, { useState, useEffect } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

import cx from "classnames"
import styles from "./contact.module.scss"

const Contact = () => {
  const [buttonText, setButtonText] = useState("Get in Touch")
  const [formState, setFormState] = useState("unsubmitted")
  const [shareLinkValue, setShareLinkValue] = useState("")

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    business: "",
    title: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    region: "",
    post_code: "",
    country: "New Zealand",
    extra_notes: "",
    share_link: "",
  })

  // Automatically click 'share' button to generate share link
  const clickShare = () => {
    document
      .getElementsByClassName("kb-final-cta-list")[0]
      .getElementsByClassName("ng-binding")[3]
      .click()
  }

  // Pull share link from input field when it exists
  useEffect(() => {
    document.getElementsByClassName("kb-final-cta-list")[0] !== undefined &&
      formInputs.share_link === "" &&
      clickShare()

    setShareLinkValue(
      document.getElementsByClassName("kb-text-box-share-link")[0] !== undefined
        ? document.getElementsByClassName("kb-text-box-share-link")[0].value
        : ""
    )
  })

  // Add share link to form submission data
  useEffect(() => {
    setFormInputs({ ...formInputs, share_link: shareLinkValue })
  }, [shareLinkValue])

  const handleSubmit = async (event) => {
    event.preventDefault()

    setButtonText("Submitting...")

    const encode = (data) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }

    await fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({ "form-name": "contact_form", ...formInputs }),
    })
      .then(() => {
        setTimeout(() => {
          setButtonText("Successfully Submitted!")
          setFormState("submitted")
        }, 1500)
      })
      .then(console.log(encode({ "form-name": "contact_form", ...formInputs })))
      .then(window.dataLayer.push({ event: "formSubmitted" }))
      .then(console.log(window.dataLayer))
  }

  return (
    <div className={styles.background}>
      <section className={styles.section} id="contact">
        <h2>Contact Us</h2>
        <p>
          Contact us today and get 20% off your first order with NZ Uniforms!{" "}
          <AnchorLink href="#terms">Terms and Conditions</AnchorLink> apply.
        </p>
        <div className={styles.content}>
          <div className={styles.intro}>
            <p>Get your team in branded gear now with NZ Uniforms.</p>
            <p>
              Fill out our contact form and a member of our design team will be
              in touch to talk about what we can do for you.{" "}
              <b>
                If you completed a design with our kit builder, it will be
                included with your submission.
              </b>
            </p>
            <p>If you prefer, you can contact us:</p>
            <ul>
              <li>by phone on 04 282 1199,</li>
              <li>
                by email at{" "}
                <a href="mailto: customerservice@nzuniforms.com">
                  customerservice@nzuniforms.com
                </a>
                ,
              </li>
              <li>
                or{" "}
                <a
                  href="https://www.nzuniforms.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  chat at nzuniforms.com
                </a>
              </li>
            </ul>
            {formState === "submitted" && (
              <p>
                <b>
                  Thanks for contacting us! We'll be in touch soon about how we
                  can help.
                </b>
              </p>
            )}
          </div>
          <div className={styles.form_container}>
            <form
              className={cx(
                styles.form,
                formState === "submitted" && styles.submitted
              )}
              data-netlify-honeypot="bot-field"
              id="contact_form"
              method="POST"
              name="contact_form"
              netlify="true"
              onSubmit={handleSubmit}
            >
              <input name="bot-field" type="hidden" />
              <input name="form-name" type="hidden" value="contact_form" />
              <input name="share_link" type="hidden" value={""} />
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.first_name !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="first_name"
                  name="first_name"
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, first_name: e.target.value })
                  }
                  pattern="[A-Za-z ]+"
                  required
                  title="Letters only."
                  type="text"
                />
                <label htmlFor="first_name">First Name</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.last_name !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="last_name"
                  name="last_name"
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, last_name: e.target.value })
                  }
                  pattern="[A-Za-z ]+"
                  required
                  title="Letters only."
                  type="text"
                />
                <label htmlFor="last_name">Last Name</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.email_address !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="email_address"
                  name="email_address"
                  onChange={(e) =>
                    setFormInputs({
                      ...formInputs,
                      email_address: e.target.value,
                    })
                  }
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                  title="Valid email addresses only."
                  type="email"
                />
                <label htmlFor="email_address">Email Address</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.phone_number !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="phone_number"
                  name="phone_number"
                  onChange={(e) =>
                    setFormInputs({
                      ...formInputs,
                      phone_number: e.target.value,
                    })
                  }
                  pattern="[0-9 ]*$"
                  required
                  title="Numbers only."
                  type="tel"
                />
                <label htmlFor="phone_number">Phone Number</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.business !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="business"
                  name="business"
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, business: e.target.value })
                  }
                  required
                  type="text"
                />
                <label htmlFor="school">Business</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.title !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="title"
                  name="title"
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, title: e.target.value })
                  }
                  required
                  type="text"
                />
                <label htmlFor="title">Title</label>
              </div>
              <div className={cx(styles.block, styles.wide)}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.address_line_1 !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="address_line_1"
                  name="address_line_1"
                  onChange={(e) =>
                    setFormInputs({
                      ...formInputs,
                      address_line_1: e.target.value,
                    })
                  }
                  required
                  type="text"
                />
                <label htmlFor="address_line_1">Address</label>
                <input
                  className={cx(
                    styles.text,
                    formInputs.address_line_1 !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="address_line_2"
                  name="address_line_2"
                  onChange={(e) =>
                    setFormInputs({
                      ...formInputs,
                      address_line_2: e.target.value,
                    })
                  }
                  type="text"
                />
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.city !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="city"
                  name="city"
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, city: e.target.value })
                  }
                  required
                  type="text"
                />
                <label htmlFor="city">City</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.region !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="region"
                  name="region"
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, region: e.target.value })
                  }
                  required
                  type="text"
                />
                <label htmlFor="region">Region / State</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.post_code !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="post_code"
                  name="post_code"
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, post_code: e.target.value })
                  }
                  pattern="[0-9]{4}"
                  required
                  title="4-digit post code only."
                  type="text"
                />
                <label htmlFor="post_code">Postal / ZIP Code</label>
              </div>
              <div className={styles.block}>
                <input
                  className={cx(
                    styles.text,
                    formInputs.country !== "" && styles.filled
                  )}
                  disabled
                  id="country"
                  name="country"
                  value="New Zealand"
                />
                <label htmlFor="country">Country</label>
              </div>
              <div className={cx(styles.block, styles.wide)}>
                <textarea
                  className={cx(
                    styles.textarea,
                    formInputs.extra_notes !== "" && styles.filled
                  )}
                  disabled={formState === "submitted" && true}
                  id="extra_notes"
                  name="extra_notes"
                  onChange={(e) =>
                    setFormInputs({
                      ...formInputs,
                      extra_notes: e.target.value,
                    })
                  }
                  type="text"
                />
                <label htmlFor="extra_notes">Extra Notes</label>
              </div>
              <div className={cx(styles.block, styles.wide)}>
                <input
                  className={styles.submit}
                  disabled={formState === "submitted" && true}
                  type="submit"
                  value={buttonText}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact
